import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—June 2007" />
	<Old2005BlogPage>
		<h2>June 2007</h2>



		<h3 className="blogdate">
		Sunday, June 03, 2007
		</h3>






		<h4 className="blogitemtitle">
		Podcast: Silent
		</h4>





			<div className="blogitembody">
			I wrote this song while living in Wellington, New Zealand, over 2006 and 2007.  It's based upon memories of Australia in 2005.<br /><br />Rachael Linton joins me with her wandering cello on this recording, which is owned by, and copyright of, Radio New Zealand.  This is actually an excerpt of a <a href="http://www.rnz.co.nz/national/programmes/nzlive/audio/2519254/nz-live-jesse-rivest">live interview/performance</a> that can be heard in full on the Radio New Zealand National website. I'm not certain that I'm allowed to make this excerpt available on my podcast; for now here it is.<br /><ul><li>(removed)</li></ul>
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 5:09 PM &nbsp;
			</div>




		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
